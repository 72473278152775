import http from "../utils/axios"
import {BASE, config, cb, onlySave} from "./base"

let BASE_PREFIX = "erp/hr/"
let BASE_PREFIX_M = "mobile/hr/"


// 入职数据保存
export function apiPurchaseHandleInductionEdit(params = {}, callback) {
  http(config(onlySave + BASE_PREFIX_M + "logHandle/inductionEdit", params, "POST")).then(res => cb(res, callback))
}

// 面试数据保存
export function apiPurchaseLogHandleInterviewEdit(params = {}, callback) {
  http(config(onlySave + BASE_PREFIX_M + "logHandle/interviewEdit", params, "POST")).then(res => cb(res, callback))
}

// 新增/详情
export function apiHrInDtl(params = {}, callback) {
  http(config(BASE + BASE_PREFIX_M + "interviewDetail", params, "POST")).then(res => cb(res, callback))
}

// 生成面试登记二维码
export function apiHrCreateInterviewQR(params = {}, callback) {
  http(config(BASE + BASE_PREFIX + "newInterview", params, "POST")).then(res => cb(res, callback))
}

// 生成面试登记二维码
export function apiHrSendMsg(params = {}, callback) {
  http(config(BASE + BASE_PREFIX + "sendMsg", params, "POST")).then(res => cb(res, callback))
}

// 面试保存-提交
export function apiHrInterviewEdit(params = {}, callback) {
  http(config(BASE + BASE_PREFIX_M + "handle/interviewEdit", params, "POST")).then(res => cb(res, callback))
}

// 入职登记表
export function apiHrCreateEntryQR(params = {}, callback) {
  http(config(BASE + BASE_PREFIX + "newInduction", params, "POST")).then(res => cb(res, callback))
}

// 入职保存-提交
export function apiHrEntry(params = {}, callback) {
  http(config(BASE + BASE_PREFIX_M + "handle/inductionEdit", params, "POST")).then(res => cb(res, callback))
}

// 采购类型选项
export function apiPurchaseOptions(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/option", params)).then(res => cb(res, callback))
}

// 采购指导价列表
export function apiPurchasePrice(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/price", params)).then(res => cb(res, callback))
}

// 采购指导价详情
export function apiPurchasePriceDtl(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/priceDetail", params)).then(res => cb(res, callback))
}

// 供应商信息列表
export function apiPurchaseCompany(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/company", params)).then(res => cb(res, callback))
}

// 采购合同列表
export function apiPurchaseContract(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/contract", params)).then(res => cb(res, callback))
}

// 采购历史指导价
export function apiPurchasePriceHistory(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/priceTrend", params, "POST")).then(res => cb(res, callback))
}


// 采购历史指导价趋势图
export function apiPurchasePriceTrend(params = {}, callback) {
  http(config(BASE + "erp/purchase/priceTrend", params)).then(res => cb(res, callback))
}

// 合作方公司历史数据
export function apiPurchaseCompanyHistory(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/companyHistory", params)).then(res => cb(res, callback))
}

// 采购项目项目列表
export function apiPurchaseProject(params = {}, callback) {
  http(config(BASE + "erp/purchase/project", params)).then(res => cb(res, callback))
}

// 采购项目合同详情
export function apiPurchaseContractDetail(params = {}, callback) {
  http(config(BASE + "erp/mobile/purchase/contractDetail", params)).then(res => cb(res, callback))
}

// 采购项目合同详情
export function apiQYWXBase(params = {}, callback) {
  http(config("https://api.dijianggroup.com/dijiang/user/ticket", params)).then(res => cb(res, callback))
}

// 采购项目合同详情
export function apiQYWXAuth(params = {}, callback) {
  http(config("/get_access_token", params, "GET")).then(res => cb(res, callback))
}

// 采购项目合同详情
export function apiQYWXJsapiTicket(params = {}, callback) {
  http(config("/jsapi_ticket", params, "GET")).then(res => cb(res, callback))
  // http(config("/jsapi_ticket", params,"GET"))
}

// 采购项目合同详情
export function apistw(params = {}, callback) {
  http(config("https://api.dijianggroup.com/dijiang/user/stw", params, "GET")).then(res => cb(res, callback))
  // http(config("/jsapi_ticket", params,"GET"))
}
