import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "*",
      redirect (to) {
        if (to.path === "/") {
          return "/index"
        } else {
          return "/index"
        }
      }
    },
    {
      name: "djnetwork",
      path: "/network",
      component: () => import("@/components/djnetwork")
    },
    {
      name: "loading",
      path: "/loading",
      component: () => import("@/components/index/loading")
    },
    {
      name: "djwait",
      path: "/wait",
      component: () => import("@/components/kmWait")
    },
    {
      name: "djAuthor",
      path: "/author",
      component: () => import("@/components/djAuthor")
    },
    {
      name: "djerror",
      path: "/error",
      // component: () => import("@/components/djError")
      component: require("@/components/djError.vue").default
    },
    {
      name: "djinvalid",
      path: "/invalid",
      // component: () => import("@/components/djError")
      component: require("@/components/djinvalid.vue").default
    },
    // 首页
    {
      name: "index",
      path: "/index",
      component: () => import("@/components/index/index"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "首页"
      }
    },
    // 首页
    {
      name: "hr",
      path: "/hr",
      component: () => import("@/components/hr/index"),
      meta: {
        login: false,
        keepAlive: true,
        position_: 0,
        title: "HR"
      }
    },
    // work
    {
      name: "works",
      path: "/hr/works",
      component: () => import("@/components/hr/works"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "work"
      }
    },
    // book
    {
      name: "book",
      path: "/hr/book",
      component: () => import("@/components/hr/book"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "book"
      },
    },
    // contract
    {
      name: "contract",
      path: "/hr/contract",
      component: () => import("@/components/hr/contract"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "contract"
      },
    },
    // edu
    {
      name: "edu",
      path: "/hr/edu",
      component: () => import("@/components/hr/edu"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "contract"
      },
    },
    // exercise
    {
      name: "exercise",
      path: "/hr/exercise",
      component: () => import("@/components/hr/exercise"),
      meta: {
        login: false,
        keepAlive: false,
        position_: 0,
        title: "exercise"
      },
    },

  ],
  linkActiveClass: "active",
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push (location) {
//   return originalPush.call(this, location).catch(err => err);
// };

export default router
